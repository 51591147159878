import { IProjectSettings, ITodoCreatedClosed } from "./Interfaces";
import { Plugin } from "./Main";
import {
    app,
    globalMatrix,
    ml,
    IDataStorage,
    matrixsdk,
    XRGetTodosAck,
    XRTodo,
    Notifications,
    XRGetProject_Needle_TrimNeedle,
    INotificationConfig,
    matrixSession,
    IItemSelectionParams,
    ControlState,
    XRTrimNeedleItem,
    IStringNumberMap,
    IItem,
    ISearchResult,
} from "../../../sdk/client";
import { IDashboardPage, IDashboardParametersBase } from "../../../sdk/plugins/interfaces";
import { Project } from "../../../sdk/objects/Project";
import { restConnection } from "../../../core/globals";
import {
    XRGetProject_JobStatus_JobsStatusWithUrl,
    XRPostProject_LaunchReport_CreateReportJobAck,
} from "../../../core/RestResult";
import { IRiskValue } from "../../../core/common/UI/Controls/riskCtrl2";
import { BaseControl } from "../../../core/common/UI/Controls/BaseControl";
import { ItemSelectionFieldHandler } from "../../../core/common/businesslogic";
import { IReportInput } from "../../../core/common/matrixlib/MatrixLibInterfaces";

// eslint-disable-next-line no-unused-vars
export class DashboardPage implements IDashboardPage<IDashboardParametersBase> {
    settings: IProjectSettings;

    _root?: JQuery;
    private notificationConfig?: INotificationConfig;

    constructor(
        private project: Project,
        private projectStorage: IDataStorage,
        private popupModeOrControl = false,
        private currentFolder?: string,
    ) {
        this.settings = {
            ...Plugin.config.projectSettingsPage.defaultSettings,
            ...globalMatrix.ItemConfig.getSettingJSON(Plugin.config.projectSettingsPage.settingName, {}),
        };
    }

    /** Customize static HTML here */
    private getDashboardDOM(): JQuery {
        return $(this.dashboardDOM);
    }

    spinningWaitXml?: JQuery;
    spinningWaitExcel?: JQuery;
    xmlExportBtn?: JQuery;
    excelExportBtn?: JQuery;
    itemSelector?: BaseControl<ItemSelectionFieldHandler>;
    itemList?: IReportInput[];

    /** Add interactive element in this function */
    renderProjectPage() {
        app.itemForm.append(
            ml.UI.getPageTitle(
                Plugin.config.dashboard.title || "",
                // @ts-ignore TODO: not sure how to make it safe
                () => {
                    return this._root;
                },
                () => {
                    this.onResize();
                },
            ),
        );
        app.itemForm.append(this.render());
    }

    onResize() {
        //Nope..
    }
    render(): JQuery {
        let that = this;
        that._root = this.getDashboardDOM();
        that.xmlExportBtn = $("#xmlExport", that._root);
        that.xmlExportBtn.click(() => {
            let exportType = "export";
            let includeFiles = $("#includeFiles", that._root).is(":checked");
            let includeAudit = $("#includeHistory", that._root).is(":checked");
            if (includeAudit) exportType += "_audit";
            if (includeFiles) exportType += "_zip";

            let format = "format=xml";

            that.xmlExportBtn?.attr("disabled", "");

            restConnection
                .postServer(matrixSession.getProject() + "/report/" + exportType + "?" + format)
                .done((response) => {
                    that.spinningWaitXml = ml.UI.getSpinningWait("Exporting...").appendTo($("#waitXML", that._root));
                    that.waitForXmlExportJob(
                        (response as XRPostProject_LaunchReport_CreateReportJobAck).jobId,
                        exportType.indexOf("zip") == -1,
                    );
                });
        });

        that.excelExportBtn = $("#excelExport", that._root);
        that.excelExportBtn.attr("disabled", "");

        let parameters: IItemSelectionParams = {
            prefix: "Export the following items:",
            buttonName: "Select data to export",
            singleFolderOnly: false,
            showOnly: globalMatrix.ItemConfig.getCategories(true).filter((cat) => {
                return ["DOC", "REPORT", "SIGN"].indexOf(cat) == -1;
            }),
        };
        let options = ml.JSON.mergeOptions(
            {},
            {
                canEdit: true,
                controlState: ControlState.FormEdit,
                help: "",

                valueChanged: async () => {
                    let v = await this.itemSelector?.getValueAsync();
                    let items = JSON.parse(v);
                    if (items != undefined) that.itemList = items;
                    that.updateExcelBtnState();
                },
                parameter: parameters,
            },
        );
        let selectorDiv = $("#selector", that._root).itemSelection(options);
        that.itemSelector = selectorDiv.getController();

        that.excelExportBtn.click(() => {
            that.excelExportBtn?.attr("disabled", "");

            let toExport =
                that.itemList?.map((i) => {
                    return i.to;
                }) || [];
            let riskCategories = globalMatrix.ItemConfig.getFieldsOfType("risk2").map((field) => field.category);
            let exportedCats = toExport.map((item) => ml.Item.parseRef(item).type);
            let exportedRisksCategories = riskCategories.filter((category) => exportedCats.indexOf(category) != -1);
            if (exportedRisksCategories.length) {
                // all categories with a review control
                app.searchAsync(
                    "mrql:" + riskCategories.map((r) => `category="${r}"`).join(" OR "),
                    "",
                    false,
                    "*",
                    undefined,
                    false,
                    true,
                ).done(function (result) {
                    const risks = result as ISearchResult[];
                    that.verifyRisks(risks, () => {
                        that.launchExcel(toExport);
                    });
                });
            } else {
                that.launchExcel(toExport);
            }
        });
        return that._root;
    }

    private launchExcel(toExport: string[]) {
        let that = this;
        let format = "format=xml";
        restConnection
            .postServer(matrixSession.getProject() + "/report/excel?" + format + "&itemList=" + toExport.join(","))
            .done((response) => {
                that.spinningWaitExcel = ml.UI.getSpinningWait("Exporting...").appendTo($("#waitExcel", that._root));
                that.waitForXlsxExportJob((response as XRPostProject_LaunchReport_CreateReportJobAck).jobId);
            });
    }

    private verifyRisks(risks: ISearchResult[], success: () => void) {
        // create a simple map to find field id of each risk category
        let fieldInCategory: IStringNumberMap = {};
        for (let field of globalMatrix.ItemConfig.getFieldsOfType("risk2")) {
            fieldInCategory[field.category] = field.field.id;
        }
        // now build a list with all 'bad' risks
        let badRisks = risks.filter((item) => {
            let fieldId = fieldInCategory[ml.Item.parseRef(item.itemId).type];
            if (!fieldId) {
                console.log("looking at risk which is not a risk!?");
                return false;
            }

            // get the risk value
            if (!item.fieldVal) {
                // maybe a new item
                return false;
            }

            let riskFields = item.fieldVal.filter((fv) => fv.id == fieldId);
            if (riskFields.length == 0 || !riskFields[0].value) {
                // no risk
                return false;
            }

            // get mitigations
            let riskValue = <IRiskValue>JSON.parse(riskFields[0].value);
            if (!riskValue.mitigations) {
                return false;
            }

            // get downlinked items from risk
            let down = item.downlinks ? item.downlinks.map((dl) => ml.Item.parseRef(dl).id) : [];

            //get mitigations which are not downlinked
            let notDown = riskValue.mitigations.filter((mit) => down.indexOf(mit.to) == -1);
            if (notDown.length > 0) {
                return true;
            }
            return false;
        });

        if (badRisks.length == 0) {
            // we can launch the exp
            success();
            return;
        }
        // show bad risks
        let br = $("#badRisks").html(
            "<p><br>The following risks need to be verified as some risk controls got removed. Visit each of them or re-index the risk category before exporting</p>",
        );
        let ul = $("<ul>").appendTo(br);
        for (let badRisk of badRisks) {
            $("<li>")
                .html(`${ml.Item.parseRef(badRisk.itemId).id}!`)
                .appendTo(ul);
        }
        ul.highlightReferences();
        // ask user what to do
        ml.UI.showConfirm(
            -1,
            {
                title: "There are risks with missing risks controls. Exported risk priority numbers might be wrong!",
                ok: "export anyway",
                nok: "review risks first",
            },
            success,
            () => {},
        );
    }

    updateExcelBtnState() {
        if (this.itemList == undefined || this.itemList.length == 0) {
            this.excelExportBtn?.attr("disabled", "");
        } else {
            this.excelExportBtn?.removeAttr("disabled");
        }
    }
    private waitForXmlExportJob(jobId: number, xmlOnly: boolean) {
        let that = this;

        restConnection.getProject("job/" + jobId).done(function (result) {
            const progress = result as XRGetProject_JobStatus_JobsStatusWithUrl;

            if (progress.status === "Error" || progress.status.indexOf("error") === 0) {
                ml.UI.showError("Error while exporting the project", "", 1000);
            } else if (progress.progress < 100) {
                ml.UI.Progress.Update(progress.progress);
                window.setTimeout(function () {
                    that.waitForXmlExportJob(jobId, xmlOnly);
                }, 500);
            } else {
                ml.UI.showSuccess(`Project ${matrixSession.getProject()} exported.`, 1000);
                if (that.spinningWaitXml) {
                    that.xmlExportBtn?.removeAttr("disabled");
                    that.spinningWaitXml.remove();
                }
                for (let i = 0; progress != undefined && i < progress.jobFile.length; i++) {
                    if (
                        (!xmlOnly && progress.jobFile[i].visibleName.endsWith(".zip")) ||
                        (xmlOnly &&
                            progress.jobFile[i].visibleName != "filter.xml" &&
                            !progress.jobFile[i].visibleName.endsWith("xsl"))
                    ) {
                        let fileparam = "/" + progress.jobFile[i].jobFileId;
                        restConnection.download("job/" + jobId + fileparam);
                    }
                }
            }
        });
    }

    private waitForXlsxExportJob(jobId: number) {
        let that = this;
        restConnection.getProject("job/" + jobId).done(function (result) {
            const progress = result as XRGetProject_JobStatus_JobsStatusWithUrl;

            if (progress.status === "Error" || progress.status.indexOf("error") === 0) {
                ml.UI.showError("Error while exporting the project", "", 1000);
            } else if (progress.progress < 100) {
                ml.UI.Progress.Update(progress.progress);
                window.setTimeout(function () {
                    that.waitForXlsxExportJob(jobId);
                }, 500);
            } else {
                ml.UI.showSuccess(`Project ${matrixSession.getProject()} exported.`, 1000);
                if (that.spinningWaitExcel) {
                    that.excelExportBtn?.removeAttr("disabled");
                    that.spinningWaitExcel.remove();
                }
                for (let i = 0; progress != undefined && i < progress.jobFile.length; i++) {
                    if (progress.jobFile[i].visibleName.endsWith(".xlsx")) {
                        let fileparam = "/" + progress.jobFile[i].jobFileId;
                        restConnection.download("job/" + jobId + fileparam);
                    }
                }
            }
        });
    }

    private dashboardDOM = `

            <div class="panel-body-v-scroll panel-default fillHeight">
            <div class="panel-body" style="display:flex; flex-direction:column">
                    <div class="panel panel-default" style="max-width:900px">
                        <div class="panel-heading">
                            <h3 class="panel-title">Excel export</h3>
                        </div>
                        <div class="panel-body">
                            <div>
                                <p>Here you can export data into excel. You can also create custom exports with structured data using DOC items.</p>
                                <p>To import data from Excel. You need to enable the excel import data in the admin client. The format for data import is similar to the export. See the manual.</p>
                                <div id="selector"></div>
                                <button class="btn btn-default" id="excelExport"> Start exporting </button>
                                <div id="waitExcel" style="display:inline"></div>
                                <div id="badRisks" style="display:inline"></div>
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default"  style="max-width:900px">
                        <div class="panel-heading">
                            <h3 class="panel-title">XML export</h3>
                        </div>
                        <div class="panel-body">
                            <div style="">
                                <p>Here you can make an xml export of the current status of the project. It can be used to make a backup or clone of all project data.</p>
                                <div><input type="checkbox" id="includeFiles"><label style="margin-left:10px"> include files</label> </div>
                                <div><input type="checkbox" id="includeHistory"><label style="margin-left:10px"> include history</label> </div>
                                <div><button class="btn btn-default" id="xmlExport"> Start export </button> </div>
                                <div id="waitXML" style="display:inline"></div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>

    `;
}
